// put site-wide dependencies here.
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#4B6056',
      light: '#27AF71',
      dark: '#565656',
      contrastText: '#ccc',
    },
    secondary: {
      main: '#34433A',
      light: '#7EAA97',
      dark: '#DEDCE2',
    },
    background: {
      default: '#DEDCE2',
    },
    text: {
      primary: '#565656',
    },
  },
});
